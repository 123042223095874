import React from 'react';
import {Grid,Button,Box, Typography} from '@mui/material';
import './style.scss'

const Footer = () => {
    return (
        <div className="footer">
            <Grid container className="footer-list">
                <Grid item xs={6} md={3}>
                <Typography variant="h7" gutterBottom component="div"><b>Company Info</b></Typography>
                <ul>
                    <li>Plots</li>
                    <li>About us</li>
                </ul>
                </Grid>
                <Grid item xs={6} md={3}>
                <Typography variant="h7" gutterBottom component="div"><b>Our Projects</b></Typography>
                <ul>
                    <li>Ongoing Projects</li>
                    <li>Upcoming Projects</li>
                    <li>Completed Projects</li>
                </ul>
                </Grid>
                <Grid item xs={6} md={3}>
                <Typography variant="h7" gutterBottom component="div"><b>Company Info</b></Typography>
                <ul>
                    <li>Terms of Service</li>
                    <li>Disclaimer</li>
                    <li>Privacy Policy</li>
                </ul>
                </Grid>
                <Grid item xs={6} md={3}>
                <Typography variant="h7" gutterBottom component="div"><b>Connect With Us</b></Typography>
                <ul>
                    <li> Twitter</li>
                    <li>Facebook</li>
                    <li>Instagram</li>
                </ul>
                </Grid>
            </Grid>
        </div>
    );
};

export default Footer;